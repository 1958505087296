/**
 * Plugin to support categories in auto complete, base logic taken from:
 * https://github.com/jquery/jquery-ui/blob/master/demos/autocomplete/categories.html
 * This plugin has been modified from its original version to match the project requirements.
 **/
  $.widget('custom.catcomplete', $.ui.autocomplete, {
  /**
   * Overwrites the create function from the auto complete widget, to avoid the categories or the geolocation elements
   * to be selectable from the results list.
   **/
  _create: function () {
    this._super();
    this.widget().menu('option', 'items', '> :not(.ui-autocomplete-category,.ui-autocomplete-geolocation)');
  },
  /**
   * Overwrites the render function to include new items (categories titles and geolocation) into the search results.
   **/
  _renderMenu: function (ul, items) {
    function _isNotNull(v) {
      return (
        v !== null &&
        typeof (v) !== null &&
        typeof (v) !== 'undefined' &&
        (
          v.length > 0 ||
          v.length === undefined // Account for booleans
        )
      );
    }
    function _isWeakFalse(b) {
      return (
        b === 'false' ||
        b === false
      );
    }

    var that = this,
      currentCategory = '';
    // Add a geoLocation option at the beginning of the list.
    if (_isNotNull(autoCompleteLabels) && _isNotNull(autoCompleteLabels.hotelsNearMe)) {
      ul.append('<li class="ui-autocomplete-geolocation ui-menu-item-wrapper">' + autoCompleteLabels.hotelsNearMe + '</li>');
    }
    // Add each item to the ul.

      $.each(items, function (index, item) {
        var li, categoryArray;
        if (item.label !== '') {
          if (_isWeakFalse(that.widget().parent().data('allLocationTypes'))) {
            if (item.category && item.category != currentCategory) {
              categoryArray = item.category.split(':');
              // categoryArray[0] is the unchanged name that we will use to add a unique class to the li.
              // categoryArray[1] is the friendly name and in current language..
              ul.append('<li class="ui-autocomplete-category ui-menu-item-wrapper ui-autocomplete-category-' + categoryArray[0].replace(/[()]/g, '') + '">' + categoryArray[1] + '</li>');
              currentCategory = item.category;
            }
          }
          li = that._renderItemData(ul, item);
          if (item.category) {
            li.attr('aria-label', item.category + ' : ' + item.label);
          }
        }
      });

  },
  /**
   * Overwrites the render item function from the auto complete widget to accept html and not only text.
   **/
  _renderItem: function (ul, item) {
    var label = this._highlightItem(item);
    return $('<li>')
      .append($('<div>').html(label))
      .appendTo(ul);
  },
  /**
   * Custom function to add strong tags to the part of the terms that match the search.
   **/
  _highlightTerm: function (value, matchedSubStrings) {
    $.each(matchedSubStrings, function (index, substring) {
      value = value.substr(0, substring.offset) +
        '<strong>' + value.substr(substring.offset, substring.length) + '</strong>' +
        value.substr(substring.offset + substring.length);
    });
    return value;
  },
  /**
   * Custom function to add custom classes to each item on the list.
   **/
  _highlightItem: function (item) {
    var label = '',
      value,
      _self = this;
    $.each(item.terms, function (index, term) {
      value = term.value;
      if (item.structuredFormatting.main_text === value) {
        value = _self._highlightTerm(value, item.structuredFormatting.main_text_matched_substrings);
      }
      if (index < 1) {
        label = '<span class="highlight">' + value + '</span>';
      } else {
        label += ', ' + value;
      }
    });
    return label;
  }
});
